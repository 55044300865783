<script setup lang="ts">
import {
    AntlerClasses,
    installAntlerComponent,
    responsive,
} from "@/Utils/component";
import { defineProps, withDefaults } from "vue";
import { Responsive } from "@/types/responsive";

export type ColumnAs = "div";
export type ColumnSize = Responsive<
    "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12"
>;

type Props = {
    as?: ColumnAs;
    size?: ColumnSize[];
    start?: ColumnSize[];
};

const props = withDefaults(defineProps<Props>(), {
    as: "div",
    size: () => ["6", "xl:12"],
    start: undefined,
});

const classes: AntlerClasses<Props> = {
    base: "",
    variants: {
        size: {
            1: responsive("col-span-1"),
            2: responsive("col-span-2"),
            3: responsive("col-span-3"),
            4: responsive("col-span-4"),
            5: responsive("col-span-5"),
            6: responsive("col-span-6"),
            7: responsive("col-span-7"),
            8: responsive("col-span-8"),
            9: responsive("col-span-9"),
            10: responsive("col-span-10"),
            11: responsive("col-span-11"),
            12: responsive("col-span-12"),
        },
        start: {
            1: responsive("col-start-1"),
            2: responsive("col-start-2"),
            3: responsive("col-start-3"),
            4: responsive("col-start-4"),
            5: responsive("col-start-5"),
            6: responsive("col-start-6"),
            7: responsive("col-start-7"),
            8: responsive("col-start-8"),
            9: responsive("col-start-9"),
            10: responsive("col-start-10"),
            11: responsive("col-start-11"),
            12: responsive("col-start-12"),
        },
    },
};
const { aClass } = installAntlerComponent("column", props, classes);
</script>

<template>
    <component
        :is="as"
        :class="aClass()"
    >
        <slot />
    </component>
</template>
