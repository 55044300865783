<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

export type GridGap = "none" | "xsmall" | "small" | "regular" | "large";

type Props = {
    gap?: GridGap;
};

const { gap = "regular" } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "grid grid-cols-6 md:grid-cols-12 flex-wrap",
    variants: {
        gap: {
            none: "gap-0",
            xsmall: "gap-2 md:gap-4",
            small: "gap-4 md:gap-6 lg:gap-8 row-gap-8",
            regular: "gap-4 gap-y-4 sm:gap-y-8 md:gap-8 ",
            large: "gap-4 gap-y-4 sm:gap-y-8 md:gap-9 lg:gap-12",
        },
    },
};
const { aClass } = installAntlerComponent("header", { gap }, classes);
</script>

<template>
    <div :class="aClass()">
        <slot />
    </div>
</template>
